var Kinetic = require('Kinetic');
'use strict';
var createHandle = function (height, color, inMarker) {
    return function (draggable, segment, parent, onDrag) {
        var handleHeight = 20;
        var handleWidth = handleHeight / 2;
        var handleY = height / 2 - 10.5;
        var handleX = inMarker ? -handleWidth + 0.5 : 0.5;
        var group = new Kinetic.Group({
                draggable: draggable,
                dragBoundFunc: function (pos) {
                    var limit;
                    if (inMarker) {
                        limit = segment.outMarker.getX() - segment.outMarker.getWidth();
                        if (pos.x > limit)
                            pos.x = limit;
                    } else {
                        limit = segment.inMarker.getX() + segment.inMarker.getWidth();
                        if (pos.x < limit)
                            pos.x = limit;
                    }
                    return {
                        x: pos.x,
                        y: this.getAbsolutePosition().y
                    };
                }
            }).on('dragmove', function (event) {
                onDrag(segment, parent);
            });
        var xPosition = inMarker ? -24 : 24;
        var text = new Kinetic.Text({
                x: xPosition,
                y: height / 2 - 5,
                text: '',
                fontSize: 10,
                fontFamily: 'sans-serif',
                fill: '#000',
                textAlign: 'center'
            });
        text.hide();
        group.label = text;
        var handle = new Kinetic.Rect({
                width: handleWidth,
                height: handleHeight,
                fill: color,
                stroke: color,
                strokeWidth: 1,
                x: handleX,
                y: handleY
            });
        var line = new Kinetic.Line({
                points: [
                    0.5,
                    0,
                    0.5,
                    height
                ],
                strokeWidth: 1,
                stroke: color,
                x: 0,
                y: 0
            });
        handle.on('mouseover', function (event) {
            if (inMarker)
                text.setX(xPosition - text.getWidth());
            text.show();
            segment.view.segmentLayer.draw();
        });
        handle.on('mouseout', function (event) {
            text.hide();
            segment.view.segmentLayer.draw();
        });
        group.add(text);
        group.add(line);
        group.add(handle);
        return group;
    };
};
function createPointHandle(height, color) {
    return function (draggable, point, parent, onDrag, onDblClick, onDragEnd) {
        var handleTop = height / 2 - 10.5;
        var handleWidth = 10;
        var handleHeight = 20;
        var handleX = 0.5;
        var group = new Kinetic.Group({
                draggable: draggable,
                dragBoundFunc: function (pos) {
                    return {
                        x: pos.x,
                        y: this.getAbsolutePosition().y
                    };
                }
            }).on('dragmove', function (event) {
                onDrag(point, parent);
            });
        if (onDblClick) {
            group.on('dblclick', function (event) {
                onDblClick(parent);
            });
        }
        if (onDragEnd) {
            group.on('dragend', function (event) {
                onDragEnd(parent);
            });
        }
        var xPosition = -handleWidth;
        var text = new Kinetic.Text({
                x: xPosition,
                y: height / 2 - 5,
                text: '',
                fontSize: 10,
                fontFamily: 'sans-serif',
                fill: '#000',
                textAlign: 'center'
            });
        text.hide();
        group.label = text;
        var handle = new Kinetic.Rect({
                width: handleWidth,
                height: handleHeight,
                fill: color,
                x: handleX,
                y: handleTop
            });
        var line = new Kinetic.Line({
                points: [
                    0,
                    0,
                    0,
                    height
                ],
                stroke: color,
                strokeWidth: 1,
                x: handleX,
                y: 0
            });
        handle.on('mouseover', function (event) {
            text.show();
            text.setX(xPosition - text.getWidth());
            point.view.pointLayer.draw();
        });
        handle.on('mouseout', function (event) {
            text.hide();
            point.view.pointLayer.draw();
        });
        group.add(handle);
        group.add(line);
        group.add(text);
        return group;
    };
}
function drawWaveform(ctx, min, max, offset_start, offset_length, y) {
    ctx.beginPath();
    min.forEach(function (val, x) {
        ctx.lineTo(offset_start + x + 0.5, y(val) + 0.5);
    });
    max.reverse().forEach(function (val, x) {
        ctx.lineTo(offset_start + (offset_length - x) + 0.5, y(val) + 0.5);
    });
    ctx.closePath();
}
function interpolateHeightGenerator(total_height) {
    var amplitude = 256;
    return function interpolateHeight(size) {
        return total_height - (size + 128) * total_height / amplitude;
    };
}
module.exports = {
    interpolateHeight: interpolateHeightGenerator,
    drawWaveform: drawWaveform,
    waveformDrawFunction: function (view, context) {
        var waveform = view.intermediateData || view.data;
        var y = interpolateHeightGenerator(view.height);
        var offset_length = waveform.offset_length;
        drawWaveform(context, waveform.min, waveform.max, 0, offset_length, y);
        context.fillStrokeShape(this);
    },
    waveformOverviewMarkerDrawFunction: function (xIndex, viewGroup, view) {
        viewGroup.waveformShape.setPoints([
            xIndex,
            0,
            xIndex,
            view.height
        ]);
    },
    niceTime: function (time, dropHundredths) {
        var hundredths, seconds, minutes, hours, result = [];
        hundredths = Math.floor(time % 1 * 100);
        seconds = Math.floor(time);
        minutes = Math.floor(seconds / 60);
        hours = Math.floor(minutes / 60);
        if (hours > 0)
            result.push(hours);
        result.push(minutes % 60);
        result.push(seconds % 60);
        for (var i = 0; i < result.length; i++) {
            var x = result[i];
            if (x < 10) {
                result[i] = '0' + x;
            } else {
                result[i] = x;
            }
        }
        result = result.join(':');
        if (!dropHundredths) {
            if (hundredths < 10) {
                hundredths = '0' + hundredths;
            }
            result += '.' + hundredths;
        }
        return result;
    },
    defaultInMarker: function (options) {
        return createHandle(options.height, options.outMarkerColor, true);
    },
    defaultOutMarker: function (options) {
        return createHandle(options.height, options.outMarkerColor, false);
    },
    defaultPointMarker: function (options) {
        return createPointHandle(options.height, options.pointMarkerColor);
    },
    defaultSegmentLabelDraw: function (options) {
        return function (segment, parent) {
            return new Kinetic.Text({
                x: 12,
                y: 12,
                text: parent.labelText,
                fontSize: 12,
                fontFamily: 'Arial, sans-serif',
                fill: '#000',
                textAlign: 'center'
            });
        };
    }
};