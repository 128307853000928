var EventEmitter = require('EventEmitter'), AudioPlayer = require('peaks/player/player'), Waveform = require('peaks/waveform/waveform.core'), mixins = require('peaks/waveform/waveform.mixins'), keyboard = require('peaks/player/player.keyboard');
'use strict';
var buildUi = function (container) {
    return {
        'player': container.querySelector('.waveform'),
        'zoom': container.querySelector('.zoom-container'),
        'overview': container.querySelector('.overview-container')
    };
};
var extend = function (to, from) {
    for (var key in from) {
        to[key] = from[key];
    }
    return to;
};
var ee = EventEmitter.EventEmitter2 || EventEmitter;
function Peaks(container) {
    ee.call(this, { wildcard: true });
    this.options = {
        zoomLevels: [
            512,
            1024,
            2048,
            4096
        ],
        dataUri: null,
        dataUriDefaultFormat: 'json',
        keyboard: false,
        nudgeIncrement: 0.01,
        inMarkerColor: '#a0a0a0',
        outMarkerColor: '#a0a0a0',
        zoomWaveformColor: 'rgba(0, 225, 128, 1)',
        overviewWaveformColor: 'rgba(0,0,0,0.2)',
        randomizeSegmentColor: true,
        height: 200,
        segmentColor: 'rgba(255, 161, 39, 1)',
        playheadColor: 'rgba(0, 0, 0, 1)',
        template: [
            '<div class="waveform">',
            '<div class="zoom-container"></div>',
            '<div class="overview-container"></div>',
            '</div>'
        ].join(''),
        pointMarkerColor: '#FF0000',
        pointDblClickHandler: null,
        pointDragEndHandler: null
    };
    this.container = container;
    this.currentZoomLevel = 0;
}
Peaks.init = function init(opts) {
    opts = opts || {};
    if (opts.audioElement) {
        opts.mediaElement = opts.audioElement;
        if (console && typeof console.log === 'function') {
            console.log('`audioElement` option is deprecated. Please use `mediaElement` instead.');
        }
    }
    if (!opts.mediaElement) {
        throw new Error('Please provide an audio element.');
    }
    if (!(opts.mediaElement instanceof HTMLMediaElement)) {
        throw new TypeError('[Peaks.init] The mediaElement option should be an HTMLMediaElement.');
    }
    if (!opts.container) {
        throw new Error('Please provide a container object.');
    }
    if (opts.container.clientWidth > 0 === false) {
        throw new TypeError('Please ensure that the container has a width.');
    }
    var instance = new Peaks(opts.container);
    extend(instance.options, opts);
    extend(instance.options, {
        segmentInMarker: mixins.defaultInMarker(instance.options),
        segmentOutMarker: mixins.defaultOutMarker(instance.options),
        segmentLabelDraw: mixins.defaultSegmentLabelDraw(instance.options),
        pointMarker: mixins.defaultPointMarker(instance.options)
    });
    if (typeof instance.options.template === 'string') {
        instance.container.innerHTML = instance.options.template;
    } else if (instance.options.template instanceof HTMLElement) {
        instance.container.appendChild(instance.options.template);
    } else {
        throw new TypeError('Please ensure you provide an HTML string or a DOM template as `template` instance option. Provided: ' + instance.options.template);
    }
    if (instance.options.keyboard)
        keyboard.init(instance);
    instance.player = new AudioPlayer(instance);
    instance.player.init(instance.options.mediaElement);
    instance.waveform = new Waveform(instance);
    instance.waveform.init(buildUi(instance.container));
    instance.seeking = false;
    instance.on('waveformOverviewReady', function () {
        instance.waveform.openZoomView();
        if (instance.options.segments) {
            instance.segments.addSegment(instance.options.segments);
        }
        if (instance.options.points) {
            instance.points.addPoint(instance.options.points);
        }
    });
    return instance;
};
Peaks.prototype = Object.create(ee.prototype, {
    segments: {
        get: function () {
            var self = this;
            function addSegment(startTime, endTime, editable, color, labelText) {
                var segments = arguments[0];
                if (typeof segments === 'number') {
                    segments = [{
                            startTime: startTime,
                            endTime: endTime,
                            editable: editable,
                            color: color,
                            labelText: labelText
                        }];
                }
                if (Array.isArray(segments)) {
                    segments.forEach(function (segment) {
                        self.waveform.segments.createSegment(segment.startTime, segment.endTime, segment.editable, segment.color, segment.labelText);
                    });
                    self.waveform.segments.render();
                } else {
                    throw new TypeError('[Peaks.segments.addSegment] Unrecognized segment parameters.');
                }
            }
            return {
                addSegment: addSegment,
                add: addSegment,
                remove: function (segment) {
                    var index = self.waveform.segments.remove(segment);
                    if (index === null) {
                        throw new RangeError('Unable to find the requested segment' + String(segment));
                    }
                    self.waveform.segments.updateSegments();
                    return self.waveform.segments.segments.splice(index, 1).pop();
                },
                removeByTime: function (startTime, endTime) {
                    endTime = typeof endTime === 'number' ? endTime : 0;
                    var fnFilter;
                    if (endTime > 0) {
                        fnFilter = function (segment) {
                            return segment.startTime === startTime && segment.endTime === endTime;
                        };
                    } else {
                        fnFilter = function (segment) {
                            return segment.startTime === startTime;
                        };
                    }
                    var indexes = self.waveform.segments.segments.filter(fnFilter).map(function (segment, i) {
                            self.waveform.segments.remove(segment);
                            return i;
                        }).sort(function (a, b) {
                            return b - a;
                        }).map(function (index) {
                            self.waveform.segments.segments.splice(index, 1);
                            return index;
                        });
                    self.waveform.segments.updateSegments();
                    return indexes.length;
                },
                removeAll: function () {
                    self.waveform.segments.removeAll();
                },
                getSegments: function () {
                    return self.waveform.segments.segments;
                }
            };
        }
    },
    points: {
        get: function () {
            var self = this;
            return {
                add: function (timestamp, editable, color, labelText) {
                    var points = arguments[0];
                    if (typeof points === 'number') {
                        points = [{
                                timestamp: timestamp,
                                editable: editable,
                                color: color,
                                labelText: labelText
                            }];
                    }
                    if (Array.isArray(points)) {
                        points.forEach(self.waveform.points.createPoint.bind(self.waveform.points));
                        self.waveform.points.render();
                    } else {
                        throw new TypeError('[Peaks.points.addPoint] Unrecognized point parameters.');
                    }
                },
                getPoints: function () {
                    return self.waveform.points.points;
                },
                removeByTime: function (timestamp) {
                    var indexes = self.waveform.points.points.filter(function (point) {
                            return point.timestamp === timestamp;
                        }).map(function (point, i) {
                            self.waveform.points.remove(point);
                            return i;
                        }).sort(function (a, b) {
                            return b - a;
                        }).map(function (index) {
                            self.waveform.points.points.splice(index, 1);
                            return index;
                        });
                    self.waveform.points.render();
                    return indexes.length;
                },
                removeAll: function removeAll() {
                    self.waveform.points.removeAll();
                }
            };
        }
    },
    time: {
        get: function () {
            var self = this;
            return {
                setCurrentTime: function setCurrentTime(time) {
                    return self.player.seekBySeconds(time);
                },
                getCurrentTime: function () {
                    return self.player.getTime();
                }
            };
        }
    },
    zoom: {
        get: function () {
            var self = this;
            return {
                zoomIn: function () {
                    self.zoom.setZoom(self.currentZoomLevel - 1);
                },
                zoomOut: function () {
                    self.zoom.setZoom(self.currentZoomLevel + 1);
                },
                setZoom: function (zoomLevelIndex) {
                    if (zoomLevelIndex >= self.options.zoomLevels.length) {
                        zoomLevelIndex = self.options.zoomLevels.length - 1;
                    }
                    if (zoomLevelIndex < 0) {
                        zoomLevelIndex = 0;
                    }
                    var previousZoomLevel = self.currentZoomLevel;
                    self.currentZoomLevel = zoomLevelIndex;
                    self.emit('zoom.update', self.options.zoomLevels[zoomLevelIndex], self.options.zoomLevels[previousZoomLevel]);
                },
                getZoom: function () {
                    return self.currentZoomLevel;
                },
                overview: function zoomToOverview() {
                    self.emit('zoom.update', self.waveform.waveformOverview.data.adapter.scale, self.options.zoomLevels[self.currentZoomLevel]);
                },
                reset: function resetOverview() {
                    self.emit('zoom.update', self.options.zoomLevels[self.currentZoomLevel], self.waveform.waveformOverview.data.adapter.scale);
                }
            };
        }
    }
});
module.exports = Peaks;