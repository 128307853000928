var Kinetic = require('Kinetic');
'use strict';
module.exports = {
    init: function (currentSampleRate, previousSampleRate, view) {
        var that = view;
        var currentTime = that.peaks.time.getCurrentTime();
        var frameData = [];
        var numOfFrames = 30;
        var input_index;
        var output_index;
        var lastFrameOffsetTime;
        if (that.segmentLayer) {
            that.segmentLayer.setVisible(false);
            that.pointLayer.setVisible(false);
        }
        if (previousSampleRate < currentSampleRate) {
            numOfFrames = 15;
        }
        for (var i = 0; i < numOfFrames; i++) {
            var frame_sample_rate = Math.round(previousSampleRate + (i + 1) * (currentSampleRate - previousSampleRate) / numOfFrames);
            var newWidthSeconds = that.width * frame_sample_rate / that.rootData.adapter.sample_rate;
            if (currentTime >= 0 && currentTime <= 0 + newWidthSeconds / 2) {
                input_index = 0;
                output_index = 0;
            } else if (currentTime <= that.rootData.duration && currentTime >= that.rootData.duration - newWidthSeconds / 2) {
                lastFrameOffsetTime = that.rootData.duration - newWidthSeconds;
                input_index = lastFrameOffsetTime * that.rootData.adapter.sample_rate / previousSampleRate;
                output_index = lastFrameOffsetTime * that.rootData.adapter.sample_rate / frame_sample_rate;
            } else {
                var oldPixelIndex = currentTime * that.rootData.adapter.sample_rate / previousSampleRate;
                input_index = oldPixelIndex - that.width / 2;
                var newPixelIndex = currentTime * that.rootData.adapter.sample_rate / frame_sample_rate;
                output_index = newPixelIndex - that.width / 2;
            }
            if (input_index < 0) {
                input_index = 0;
            }
            var resampled = that.rootData.resample({
                    scale: frame_sample_rate,
                    input_index: Math.floor(input_index),
                    output_index: Math.floor(output_index),
                    width: that.width
                });
            frameData.push(resampled);
            previousSampleRate = frame_sample_rate;
        }
        return new Kinetic.Animation(this.onFrameData(view, frameData), view);
    },
    onFrameData: function (view, frameData) {
        var that = view;
        that.intermediateData = null;
        return function (frame) {
            if (frameData.length) {
                that.intermediateData = frameData.shift();
                that.zoomWaveformLayer.draw();
            } else {
                this.stop();
                that.segmentLayer.setVisible(true);
                that.pointLayer.setVisible(true);
                that.seekFrame(that.data.at_time(that.currentTime));
            }
        };
    }
};