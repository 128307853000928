var WaveformData = require('waveform-data'), WaveformOverview = require('peaks/views/waveform.overview'), WaveformZoomView = require('peaks/views/waveform.zoomview'), WaveformSegments = require('peaks/markers/waveform.segments'), WaveformPoints = require('peaks/markers/waveform.points');
'use strict';
var isXhr2 = 'withCredentials' in new XMLHttpRequest();
module.exports = function (peaks) {
    return {
        init: function (ui) {
            this.ui = ui;
            var that = this;
            that.getRemoteData(peaks.options);
        },
        getRemoteData: function (options) {
            var that = this;
            var xhr = new XMLHttpRequest();
            var uri = null;
            var requestType = null;
            var builder = null;
            if (options.dataUri) {
                if (typeof options.dataUri === 'string') {
                    var dataUri = {};
                    dataUri[options.dataUriDefaultFormat || 'json'] = options.dataUri;
                    options.dataUri = dataUri;
                }
                if (typeof options.dataUri === 'object') {
                    [
                        'ArrayBuffer',
                        'JSON'
                    ].some(function (connector) {
                        if (window[connector]) {
                            requestType = connector.toLowerCase();
                            uri = options.dataUri[requestType];
                            return Boolean(uri);
                        }
                    });
                }
            }
            if (!options.dataUri && WaveformData.builders.webaudio.getAudioContext()) {
                requestType = 'arraybuffer';
                uri = options.mediaElement.currentSrc || options.mediaElement.src;
                builder = 'webaudio';
            }
            if (!uri) {
                throw new Error('Unable to determine a compatible dataUri format for this browser.');
            }
            xhr.open('GET', uri, true);
            if (isXhr2) {
                try {
                    xhr.responseType = requestType;
                } catch (e) {
                }
            }
            xhr.onload = function (response) {
                if (this.readyState === 4 && this.status === 200) {
                    if (builder) {
                        WaveformData.builders[builder](response.target.response, that.handleRemoteData.bind(that));
                    } else {
                        that.handleRemoteData(response.target, xhr);
                    }
                }
            };
            xhr.send();
        },
        handleRemoteData: function (remoteData, xhr) {
            this.origWaveformData = remoteData instanceof WaveformData ? remoteData : WaveformData.create(remoteData);
            var overviewWaveformData = this.origWaveformData.resample(this.ui.player.clientWidth);
            this.waveformOverview = new WaveformOverview(overviewWaveformData, this.ui.overview, peaks);
            peaks.emit('waveformOverviewReady');
            this.bindResize();
        },
        openZoomView: function () {
            var that = this;
            that.waveformZoomView = new WaveformZoomView(that.origWaveformData, that.ui.zoom, peaks);
            that.segments = new WaveformSegments(peaks);
            that.segments.init();
            that.points = new WaveformPoints(peaks);
            that.points.init();
            peaks.emit('waveformZoomReady');
        },
        bindResize: function () {
            var that = this;
            window.addEventListener('resize', function () {
                that.ui.overview.hidden = true;
                that.ui.zoom.hidden = true;
                if (that.resizeTimeoutId)
                    clearTimeout(that.resizeTimeoutId);
                that.resizeTimeoutId = setTimeout(function () {
                    var w = that.ui.player.clientWidth;
                    var overviewWaveformData = that.origWaveformData.resample(w);
                    peaks.emit('resizeEndOverview', w, overviewWaveformData);
                    peaks.emit('window_resized', w, that.origWaveformData);
                }, 500);
            });
            peaks.on('overview_resized', function () {
                that.ui.overview.removeAttribute('hidden');
            });
            peaks.on('zoomview_resized', function () {
                that.ui.zoom.removeAttribute('hidden');
            });
            peaks.on('user_seek.*', function (time) {
                peaks.player.seekBySeconds(time);
            });
            peaks.on('user_scrub.*', function (time) {
                peaks.player.seekBySeconds(time);
            });
        }
    };
};