'use strict';
function BaseShape() {
}
function noop() {
}
function throwUndefined() {
    throw new Error('You should extend this method in your parent class.');
}
BaseShape.prototype = {
    createShape: throwUndefined,
    update: noop
};
module.exports = BaseShape;