var BaseShape = require('peaks/markers/shapes/base'), mixins = require('peaks/waveform/waveform.mixins'), Kinetic = require('Kinetic');
'use strict';
var WaveShape = Object.create(BaseShape.prototype);
WaveShape.createShape = function createShape(segmentData, view) {
    var shape = new Kinetic.Shape({
            fill: segmentData.color,
            strokeWidth: 0,
            opacity: 1
        });
    shape.setDrawFunc(WaveShape.drawFunc.bind(shape, view, segmentData.id));
    return shape;
};
WaveShape.drawFunc = function WaveShapedrawFunc(view, segmentId, context) {
    var waveformData = view.data;
    if (waveformData.segments[segmentId] === undefined) {
        return;
    }
    var segment = waveformData.segments[segmentId];
    var offset_length = segment.offset_length;
    var offset_start = segment.offset_start - waveformData.offset_start;
    var y = mixins.interpolateHeight(view.height);
    mixins.drawWaveform(context, segment.min, segment.max, offset_start, offset_length, y);
    context.fillStrokeShape(this);
};
module.exports = WaveShape;