var mixins = require('peaks/waveform/waveform.mixins'), Kinetic = require('Kinetic');
'use strict';
function roundUpToNearest(value, multiple) {
    var remainder = value % multiple;
    if (remainder === 0) {
        return value;
    } else {
        return value + multiple - remainder;
    }
}
function WaveformAxis(view) {
    this.view = view;
    this.axisShape = new Kinetic.Shape({
        fill: 'rgba(38, 255, 161, 1)',
        strokeWidth: 0,
        opacity: 1
    });
    this.axisShape.setDrawFunc(this.axisDrawFunction.bind(this, view));
    this.view.uiLayer.add(this.axisShape);
}
WaveformAxis.prototype.getAxisLabelScale = function () {
    var baseSecs = 1;
    var steps = [
            1,
            2,
            5,
            10,
            20,
            30
        ];
    var minSpacing = 60;
    var index = 0;
    var secs;
    for (;;) {
        secs = baseSecs * steps[index];
        var pixels = this.view.data.at_time(secs);
        if (pixels < minSpacing) {
            if (++index == steps.length) {
                baseSecs *= 60;
                index = 0;
            }
        } else {
            break;
        }
    }
    return secs;
};
WaveformAxis.prototype.axisDrawFunction = function (view, context) {
    var currentFrameStartTime = view.data.time(view.frameOffset);
    var markerHeight = 10;
    var axisLabelIntervalSecs = this.getAxisLabelScale();
    var firstAxisLabelSecs = roundUpToNearest(currentFrameStartTime, axisLabelIntervalSecs);
    var axisLabelOffsetSecs = firstAxisLabelSecs - currentFrameStartTime;
    var axisLabelOffsetPixels = this.view.data.at_time(axisLabelOffsetSecs);
    context.strokeStyle = '#ccc';
    context.lineWidth = 1;
    context.font = '11px sans-serif';
    context.fillStyle = '#aaa';
    context.textAlign = 'left';
    context.textBaseline = 'bottom';
    var secs = firstAxisLabelSecs;
    var x;
    for (;;) {
        x = axisLabelOffsetPixels + this.view.data.at_time(secs - firstAxisLabelSecs);
        if (x >= this.view.width) {
            break;
        }
        context.beginPath();
        context.moveTo(x + 0.5, 0);
        context.lineTo(x + 0.5, 0 + markerHeight);
        context.moveTo(x + 0.5, this.view.height);
        context.lineTo(x + 0.5, this.view.height - markerHeight);
        context.stroke();
        var label = mixins.niceTime(secs, true);
        var labelWidth = context._context.measureText(label).width;
        var labelX = x - labelWidth / 2;
        var labelY = this.view.height - 1 - markerHeight;
        if (labelX >= 0) {
            context.fillText(label, labelX, labelY);
        }
        secs += axisLabelIntervalSecs;
    }
};
module.exports = WaveformAxis;